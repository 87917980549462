import React, { createContext, useContext, useState, useEffect } from 'react';
import { API_URL } from './../utils/config';

const AccountContext = createContext();

export const useAccount = () => {
  return useContext(AccountContext);
};

export const AccountProvider = ({ children }) => {
  const [account, setAccount] = useState(null);

  const ChangeLogged = () => {
    aggiornaAccount();
    return null;
  };

  useEffect(() => {
    const fetchAccountInfo = async () => {
      try {
        const response = await fetch(API_URL+`ideawebcomid/account/`, {credentials: 'include'}); // Sostituisci con il percorso reale dell'API
        const accountData = await response.json();
        setAccount(accountData.data);
      } catch (error) {
        console.error('Errore nel recupero delle informazioni dell\'account', error);
      }
    };

    fetchAccountInfo();
  }, []);

 const aggiornaAccount = () => {
    const fetchAccountInfo = async () => {
      try {
        const response = await fetch(API_URL+`ideawebcomid/account/`, {credentials: 'include'}); // Sostituisci con il percorso reale dell'API
        const accountData = await response.json();
        setAccount(accountData.data);
      } catch (error) {
        console.error('Errore nel recupero delle informazioni dell\'account', error);
      }
    };

    fetchAccountInfo();
  };

  const value = {
    account
  };

  return (
    <AccountContext.Provider value={{account, ChangeLogged}}>
      {children}
    </AccountContext.Provider>
  );
};