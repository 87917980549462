import React, { useState } from 'react';
import './styles/app.css';
import {NextUIProvider} from "@nextui-org/react";
import {ThemeProvider as NextThemesProvider} from "next-themes";
import SitoSiderbar from './components/website/sidebar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/home';
import SitoNavbar from './components/website/navbar';
import Spazio from './components/website/spazio';
import AccountPage from './pages/account';
import LoginPage from './pages/login';
import RequireAuth from './components/auth/requireAuth';
import { AccountProvider } from './contexts/AccountContext';
import VerificaAccountPage from './pages/verificaaccount';

function App() {
  return (
    <NextUIProvider>
      <NextThemesProvider attribute="class" defaultTheme="dark">
        <AccountProvider>
          <Router>
            <div>
              <SitoNavbar />
              <Routes>
                <Route key="account" path="/account/" exact element={<><RequireAuth><AccountPage /></RequireAuth></>} />
                <Route key="login" path="/login/" exact element={<><LoginPage /></>} />
                <Route key="attivazione" path="/attivazione/:keyattivazione/" exact element={<><VerificaAccountPage /></>} />
                <Route index key="home" path="/" exact element={<><RequireAuth><HomePage /></RequireAuth></>} />
              </Routes>
            </div>
          </Router>
        </AccountProvider>
      </NextThemesProvider>
    </NextUIProvider>
  );
}

export default App;
