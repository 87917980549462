import React, { useEffect, useState } from 'react';
import { API_URL } from './../utils/config';
import { useParams } from 'react-router-dom';

export default function VerificaAccountPage() {
    const { keyattivazione } = useParams();
    const [data, setData] = useState(false);

    useEffect(() => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
      
            var urlencoded = new URLSearchParams();
            urlencoded.append("chiaveattivazione", keyattivazione);
      
            var requestOptions = {
              credentials: 'include',
              method: 'POST',
              headers: myHeaders,
              body: urlencoded,
              redirect: 'follow'
            };
      
            fetch(API_URL+`ideawebcomid/verificaaccount/`, requestOptions)
              .then(response => response.json())
              .then(result => setData(result))
              .catch(error => console.log('error', error));
          } catch (error) {
            /*AntDialog.show({
              title: "OPS!",
              content: (
                <>
                  <div>{t("error_occurred")}</div>
                </>
              ),
              closeOnAction: true,
              actions: [[
                    {
                        key: 'cancel',
                        text: 'Chiudi',
                        bold: true,
                    }
                ]],
            });*/
            //setLoading(false);
          }
    }, [keyattivazione])

    return (
        <>
            {!keyattivazione && (
                <section class="">
                    <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                        <div class="mx-auto max-w-screen-sm text-center">
                            <h1 class="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-red-600 dark:text-red-500">404</h1>
                            <p class="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">Chiave di attivazione non trovata!</p>
                        </div>   
                    </div>
                </section>
            )}
            {data && (
                <>
                    {data.status && (
                        <section class="">
                            <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                                <div class="mx-auto max-w-screen-sm text-center">
                                    <h1 class="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600 dark:text-primary-500">okokok</h1>
                                    <p class="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">Account attivato con successo!</p>
                                </div>   
                            </div>
                        </section>
                    )}
                    {!data.status && (
                        <section class="">
                            <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                                <div class="mx-auto max-w-screen-sm text-center">
                                    <h1 class="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-red-600 dark:text-red-500">OPS</h1>
                                    <p class="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">{data.message}</p>
                                </div>   
                            </div>
                        </section>
                    )}
                </>
            )}
        </>
    )
}