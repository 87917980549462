import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAccount } from './../../contexts/AccountContext';
import { Progress } from "@nextui-org/react";

function RequireAuth({children}) {
    const { account } = useAccount();
    let location = useLocation();
    if(account) {
        if (!account.logged) {
            return <Navigate to="/login" state={{ from: location }} replace />;
        }
        return children;
    } else {
        return <Progress
            size="lg"
            isIndeterminate
            aria-label="Attendi..."
            className="w-full"
        />;
    }
}

export default RequireAuth;