import React from "react";
import {Card, CardHeader, CardBody, CardFooter, Image, Button} from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { useAccount } from './../contexts/AccountContext';

export default function AccountPage() {
  const navigate=useNavigate();
  const { account } = useAccount();
  return (
        <div className="container">
            <h3 className="text-3xl font-bold mb-3">Account</h3>
            <dl class="text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">
                <div class="flex flex-col pb-3">
                    <dt class="mb-1 text-gray-500 md:text-lg dark:text-gray-400">Indirizzo email</dt>
                    <dd class="text-lg font-semibold">{account.email}</dd>
                </div>
                <div class="flex flex-col py-3">
                    <dt class="mb-1 text-gray-500 md:text-lg dark:text-gray-400">Home address</dt>
                    <dd class="text-lg font-semibold">92 Miles Drive, Newark, NJ 07103, California, USA</dd>
                </div>
                <div class="flex flex-col pt-3">
                    <dt class="mb-1 text-gray-500 md:text-lg dark:text-gray-400">Phone number</dt>
                    <dd class="text-lg font-semibold">+00 123 456 789 / +12 345 678</dd>
                </div>
            </dl>
        </div>
  )
}