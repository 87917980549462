import React from 'react';
import {Input, Checkbox} from "@nextui-org/react";
import { FaRegEye, FaEyeSlash } from "react-icons/fa6";

export default function LoginPage() {
    const [passwordVisibile, setPasswordVisibile] = React.useState(false);

    return (
    <section class="bg-black">
        <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen md:-mt-[60px] lg:py-0">
            <a href="#" class="flex items-center mb-6 text-2xl font-bold font-logo text-gray-900 dark:text-white">ideawebcom id</a>
            <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                    <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                        Accedi
                    </h1>
                    <form class="space-y-4 md:space-y-6" action="#">
                        <div>
                            <Input
                                isRequired
                                type="email"
                                label="Email"
                                className="mb-2"
                                />
                        </div>
                        <div>
                            <Input
                                label="Password"
                                placeholder="Inserisci la tua password"
                                endContent={
                                    <button className="focus:outline-none" type="button" onClick={() => setPasswordVisibile(!passwordVisibile)}>
                                    {passwordVisibile ? (
                                        <FaEyeSlash className="text-2xl text-default-400 pointer-events-none" />
                                    ) : (
                                        <FaRegEye className="text-2xl text-default-400 pointer-events-none" />
                                    )}
                                    </button>
                                }
                                type={passwordVisibile ? "text" : "password"}
                                className="mb-2"
                            />
                        </div>
                        <div class="flex items-center justify-between">
                            <div class="flex items-start">
                                <div class="flex items-center h-5">
                                    <Checkbox defaultSelected>Ricordati</Checkbox>
                                </div>
                            </div>
                            <a href="#" class="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500">Resetta la password</a>
                        </div>
                        <button type="submit" class="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Sign in</button>
                        <p class="text-sm font-light text-gray-500 dark:text-gray-400">
                            Non hai un account? <a href="#" class="font-medium text-primary-600 hover:underline dark:text-primary-500">Registrati</a>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    </section>
    )
}