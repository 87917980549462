import React from "react";
import {Navbar, NavbarBrand, NavbarContent, NavbarItem, Button, NavbarMenuToggle, NavbarMenu, NavbarMenuItem,} from "@nextui-org/react";
import { Link } from "react-router-dom";

function SitoNavbar() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  return (
    <Navbar maxWidth="full" onMenuOpenChange={setIsMenuOpen}>
      <NavbarContent>
        <NavbarMenuToggle
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
        />
        <NavbarBrand>
          <p className="font-bold text-xl font-logo">ideawebcom id</p>
        </NavbarBrand>
      </NavbarContent>

      <NavbarContent justify="end">
        {/*<NavbarItem className="hidden lg:flex">
          <Link href="#">Login</Link>
        </NavbarItem>
        <NavbarItem>
          <Button as={Link} color="primary" href="#" variant="flat">
            Sign Up
          </Button>
        </NavbarItem>*/}
      </NavbarContent>
      <NavbarMenu>
          <NavbarMenuItem>
            <Link
              color={"primary"}
              className="w-full"
              to="/"
              size="lg"
            >Home</Link>
          </NavbarMenuItem>
          <NavbarMenuItem><strong>Account</strong></NavbarMenuItem>
          <NavbarMenuItem>
            <Link
              color={"primary"}
              className="w-full"
              to="/account/"
              size="lg"
            >Profilo</Link>
          </NavbarMenuItem>
          <NavbarMenuItem>
            <Link
              color={"primary"}
              className="w-full"
              to="/password/"
              size="lg"
            >Password</Link>
          </NavbarMenuItem>
          <NavbarMenuItem>
            <Link
              color={"primary"}
              className="w-full"
              to="/2fa/"
              size="lg"
            >Autenticazione a due fattori</Link>
          </NavbarMenuItem>
          <NavbarMenuItem><strong>Servizi</strong></NavbarMenuItem>
          <NavbarMenuItem>
            <Link
              color={"primary"}
              className="w-full"
              to="/acquisti/"
              size="lg"
            >Acquisti</Link>
          </NavbarMenuItem>
      </NavbarMenu>
    </Navbar>
  );
}

export default SitoNavbar;